import { openButtonSize } from '@/typography/constants'

import { IAppProps } from './App.types'

const handleOnResize = () => {
  const iframe = document.querySelector('#sberHealth') as HTMLElement
  if (iframe) {
    Object.assign(iframe.style, {
      height: `${window.innerHeight}px`,
      width: `${window.innerWidth}px`,
    })
  }
}

export const init = ({
  clinicId,
  partnerId,
  cityId,
  buttonColor,
  textColor,
  position,
}: IAppProps): void => {
  const container = document.querySelector('#sber-health') as HTMLElement

  if (!container) {
    throw new Error('Element with "sber-health" class did not found')
  }

  const defaultIframeStyles = {
    position: 'fixed',
    right: position === 'left' ? null : '32px',
    left: position === 'left' ? '32px' : null,
    bottom: '32px',
    height: `${openButtonSize}px`,
    width: `${openButtonSize}px`,
    border: 0,
    zIndex: 99_999,
  }

  const iframe = document.createElement('iframe')
  iframe.name = 'sberHealth'
  iframe.id = 'sberHealth'
  iframe.src = process.env.REACT_APP_DOMAIN as string
  iframe.referrerPolicy = 'no-referrer'
  iframe.sandbox.add(
    'allow-forms',
    'allow-scripts',
    'allow-same-origin',
    'allow-modals',
    'allow-popups',
  )

  Object.assign(iframe.style, defaultIframeStyles)
  Object.assign(container?.style, {
    overflow: 'visible',
    height: 0,
    width: 0,
  })

  iframe.addEventListener('load', () => {
    const iframeWindow = iframe?.contentWindow
    if (iframeWindow) {
      iframeWindow.postMessage(
        { type: 'renderWidget', clinicId, partnerId, cityId, buttonColor, textColor, position },
        process.env.REACT_APP_DOMAIN as string,
      )
    }
  })

  container.append(iframe)

  window.onmessage = (event: MessageEvent) => {
    if (event.data && event.data.type === 'openWidget') {
      Object.assign(iframe.style, {
        right: 0,
        left: 0,
        bottom: 0,
        height: `${window.innerHeight}px`,
        width: `${window.innerWidth}px`,
      })
      window.addEventListener('resize', handleOnResize)
    } else if (event.data && event.data.type === 'closeWidget') {
      Object.assign(iframe.style, defaultIframeStyles)
      window.removeEventListener('resize', handleOnResize)
    }
  }
}
