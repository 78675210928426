export const dimensions = {
  largeScreen: 1890,
  laptopLarge: 1324,
  laptop: 1279,
  tablet: 855,
  phone: 644,
  phoneMedium: 380,
  phoneSmall: 320,
}

export const media = {
  largeScreen: `@media (min-width: ${dimensions.largeScreen}px)`,
  laptopLarge: `@media (max-width: ${dimensions.laptopLarge}px)`,
  laptop: `@media (max-width: ${dimensions.laptop}px)`,
  tablet: `@media (max-width: ${dimensions.tablet}px)`,
  phone: `@media (max-width: ${dimensions.phone}px)`,
  phoneMedium: `@media (max-width: ${dimensions.phoneMedium}px)`,
  phoneSmall: `@media (max-width: ${dimensions.phoneSmall}px)`,
}
