import { media } from './media'

export const openButtonSize = 96

export const contentPadding = `
  padding: 0 24px;

  ${media.phone} {
    padding: 0 16px;
  }
`
